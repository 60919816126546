/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/tooltipster@4.2.7/dist/js/tooltipster.bundle.min.js
 * - /npm/swiper@4.5.1/dist/js/swiper.min.js
 * - /npm/jquery.qrcode@1.0.3/jquery.qrcode.min.js
 * - /npm/sweetalert2@11.0.17/dist/sweetalert2.min.js
 * - /gh/highlightjs/cdn-release@9.18.1/build/highlight.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
